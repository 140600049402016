<template>
  <ProjectsPage />
</template>

<script>
import ProjectsPage from './components/ProjectsPage.vue'

export default {
  name: 'App',
  components: {
    ProjectsPage
  }
}
</script>

<style>

html {
  background: url('@/assets/File.png') no-repeat center center fixed; 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  
}

#app {
  font-family: 'League Spartan', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100%;
}
</style>
